.step-list {
    display: flex;
    justify-content: space-between; /* Space between steps */
    align-items: center;
  }
  
  .step {
    display: flex;
    flex-direction: column; /* Stack icon and description vertically */
    align-items: center;
    width: 200px; /* Fixed width for each step */
    text-align: center; /* Center align the text */
    margin: 0 10px; /* Margin between steps */
  }
  
  .step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 40px;
    height: 40px;
    margin-bottom: 10px; /* Space between icon and description */
  }
  
  .number-icon {
    font-size: 40px;
  }
  
  .step-number {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
  }
  
  .step-description {
    flex: 1;
  }
  